@tailwind base;
@tailwind components;

@import 'antd/dist/antd.css';

@font-face {
    font-family: 'Gotham Book';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/GothamBook.ttf');
}
@font-face {
    font-family: 'Gotham Book Italic';
    font-style: italic;
    font-weight: 400;
    src: url('../fonts/Gotham-BookItalic.otf');
}
@font-face {
    font-family: 'Gotham Light';
    font-style: normal;
    font-weight: 300;
    src: url('../fonts/GothamLight.ttf');
}
@font-face {
    font-family: 'Gotham Light Italic';
    font-style: italic;
    font-weight: 300;
    src: url('../fonts/GothamLightItalic.ttf');
}
@font-face {
    font-family: 'Gotham Medium';
    font-style: normal;
    font-weight: 500;
    src: url('../fonts/GothamMedium.ttf');
}
@font-face {
    font-family: 'Gotham Medium Italic';
    font-style: italic;
    font-weight: 500;
    src: url('../fonts/GothamMediumItalic.ttf');
}
@font-face {
    font-family: 'Gotham Bold';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/GothamBold.ttf');
}
@font-face {
    font-family: 'Gotham Bold Italic';
    font-style: italic;
    font-weight: 700;
    src: url('../fonts/GothamBoldItalic.ttf');
}
@font-face {
    font-family: 'Gotham Black';
    font-style: normal;
    font-weight: 500;
    src: url('../fonts/Gotham-Black.otf');
}
@font-face {
    font-family: 'Gotham Thin';
    font-style: normal;
    font-weight: 500;
    src: url('../fonts/Gotham-Thin.otf');
}
@font-face {
    font-family: 'Gotham XLight';
    font-style: normal;
    font-weight: 500;
    src: url('../fonts/Gotham-XLight.otf');
}
@font-face {
    font-family: 'Gotham Thin Italic';
    font-style: italic;
    font-weight: 500;
    src: url('../fonts/Gotham-ThinItalic.otf');
}
@font-face {
    font-family: 'Gotham Ultra Italic';
    font-style: italic;
    font-weight: 500;
    src: url('../fonts/Gotham-UltraItalic.otf');
}
@font-face {
    font-family: 'Gotham XLight Italic';
    font-style: italic;
    font-weight: 500;
    src: url('../fonts/Gotham-XLightItalic.otf');
}
@font-face {
    font-family: 'Gotham Rounded Bold';
    font-style: italic;
    font-weight: 500;
    src: url('../fonts/Gotham-Rounded-Bold.otf');
}
@font-face {
    font-family: 'Gotham Rounded Book';
    font-style: italic;
    font-weight: 500;
    src: url('../fonts/Gotham-Rounded-Book.otf');
}
@font-face {
    font-family: 'Gotham Rounded Medium';
    font-style: italic;
    font-weight: 500;
    src: url('../fonts/Gotham-Rounded-Medium.otf');
}

@tailwind utilities;

.header {
    background: linear-gradient(to right, #563997 0%, #5e3696 14%, #743193 35%, #98288f 62%, #ca1b89 92%, #d91888);
    @apply text-white;
}

@media (min-width: 1024px) {
    .header {
        background: white;
    }
}


.login-card {
    width: 30%;
}

.primary-button {
    background-image: linear-gradient(to right, #563997 0%, #5e3696 14%, #743193 35%, #98288f 62%, #ca1b89 92%, #d91888);
    background-blend-mode: color;
}

.bg-gradient-primary {
    background-image: linear-gradient(to right, #563997 0%, #5e3696 14%, #743193 35%, #98288f 62%, #ca1b89 92%, #d91888);
    background-blend-mode: color;
    @apply text-white;
}

@keyframes fpFadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -8px, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.ant-picker-input > input {
    font-size: 13px;
    color: @apply text-body;
}

.ant-picker-input > input::placeholder {
    font-size: 13px;
    color: @apply text-body;
}

.ant-picker-input > .ant-picker-suffix {
    display: none;
}

body {
    font-family: 'Gotham Rounded Book' !important;
}

.react-tel-input .form-control {
    position: relative;
    padding-left: 48px;
    background: #FFFFFF;
    border: 1px solid #c2a7cd !important;
    border-radius: 4px !important;
    width: 100% !important;
    outline: none;
    font-family: 'Gotham Rounded Book' !important;
    height: 41px! important;
    font-size: 12px !important;
    letter-spacing: normal !important;
    color: @apply !text-body;
}

.react-tel-input .form-control:focus {
    outline: none;
    border: 1px solid #a37cb5 !important;
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color); --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color); box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

